@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');
@import 'datepicker.scss';
@import 'swatches.scss';

html, body, #app {
    font-family: 'Nunito', sans-serif;
    @apply min-h-screen
}

a {
    @apply text-black
}

a:hover {
    @apply text-sbr-orange
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
 }

 .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
 }

 .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
 }

 .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
 }

.pagination {
    @apply flex w-auto font-sans justify-center
}

.pagination {
    a {
        cursor: pointer;
        padding: 8px;
    }
}

.pagination-inactive {
    opacity: 0.5 !important;
    a {
        opacity: 0.4;
        cursor: default;
    }
}

.pagination-page,
.pagination-next,
.pagination-prev {
    &.active {
        @apply font-bold text-sbr-orange
    }

    &:hover {
        @apply text-sbr-orange
    }

    @apply block text-black px-3 py-2 border border-gray-400 bg-white
}

.pagination-inactive {
    @apply text-black
}

.mx-datepicker,
.mx-datepicker-range {
    width: 100% !important
}



.multiselect__tags {
    @apply appearance-none block w-full bg-white text-gray-700 border border-gray-400 pt-2 px-4 mb-3 #{!important}

}

.multiselect__select {
    @apply leading-normal #{!important}
}

.multiselect__input {
    @apply bg-gray-200 text-gray-700 text-base #{!important}
}

.multiselect__placeholder {
    @apply pl-2 pt-0 mb-2 text-gray-400 text-base #{!important}
}

.multiselect__input::placeholder {
    @apply pl-2 pt-2 text-gray-500 text-base #{!important}
}

.multiselect__single {
    @apply pl-2 bg-white text-gray-700 text-base #{!important}
}

.multiselect__spinner {
    @apply bg-gray-200 #{!important}
}
