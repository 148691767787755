$namespace: 'sbr'; // change the 'mx' to 'xmx'. then <date-picker prefix-class="xmx" />

$default-color: #555;
$primary-color: #1284e7;

@import '~vue-datepicker-next/scss/index.scss';

.sbr-datepicker {
    @apply w-full;
}

.sbr-icon-clear {
    @apply mr-4;
}

.sbr-icon-calendar {
    @apply mr-4;
}
